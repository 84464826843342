<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-lg-flex">
      <dark-Toggler class="d-lg-block" />
    </div>
    <Locale />
    <div v-if="isNotificationsAllowed" class="mx-1">
      <notification-dropdown />
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ `${userData.firstName} ${userData.lastName}` }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.logo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item :to="{ name: 'account-setting' }" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Settings</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { mapState } from "vuex";
// import { onUnmounted } from '@vue/composition-api'
import Locale from "@/@core/layouts/components/app-navbar/components/Locale.vue";
import NotificationDropdown from "@/@core/layouts/components/app-navbar/components/NotificationDropdown.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    Locale,
    NotificationDropdown,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState("accountSettings", ["userData", "roles"]),
    isNotificationsAllowed() {
      const hasAllowedRole = (allowedRoles = [], userRoles = []) => {
        return userRoles.some((role) => allowedRoles.includes(role));
      };
      return hasAllowedRole(["Admin", "Category Manager", "Editor"], this.roles);
    },
  },
  methods: {
    logout() {
      // eslint-disable-next-line no-unused-vars
      this.$store.dispatch("auth/logout", {}).then((response) => {
        localStorage.clear();
        this.$router.replace("/login");
      });
    },
  },
};
</script>
