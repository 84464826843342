/* eslint-disable */
import i18n from "@/main.js";

export default [
  {
    title: i18n.t("navigation.user-management"),
    titleEng: "Role Management",
    route: "admin-management",
    icon: "UserIcon",
    id: 1,
  },
  {
    title: i18n.t("navigation.role-management"),
    titleEng: "Role Management",
    route: "role-management",
    icon: "UserCheckIcon",
    id: 2,
    children: [
      {
        title: i18n.t("navigation.role-management"),
        route: "role-management",
        allowedRoles: ["Category Manager", "Admin", "Editor", "Analyst"],
      },
      {
        title: i18n.t("navigation.category-management"),
        route: "category-management",
        allowedRoles: ["Category Manager", "Admin", "Editor", "Analyst"],
      },
    ],
  },
  {
    title: i18n.t("navigation.vendor-management"),
    titleEng: "Vendor Management",
    route: "vendor-management",
    icon: "UserIcon",
    id: 3,
    children: [
      {
        title: i18n.t("navigation.vendors"),
        route: "vendors",
        allowedRoles: [
          "Category Manager",
          "Admin",
          "Editor",
          "Support",
          "Analyst",
          "Accountant",
        ],
      },
      {
        title: i18n.t("navigation.applications"),
        route: "applications",
        allowedRoles: ["Admin", "Editor"],
      },
    ],
  },
  {
    title: i18n.t("navigation.products"),
    titleEng: "Products",
    route: "products",
    icon: "ShoppingBagIcon",
    id: 3,
    children: [
      {
        title: i18n.t("navigation.products-products"),
        route: "products",
      },
      {
        title: i18n.t("navigation.products-review"),
        route: "pending-products",
        allowedRoles: ["Admin", "Editor", "Category Manager", "Analyst"],
      },
      {
        title: i18n.t("navigation.addons"),
        route: "addons",
        allowedRoles: ["Admin", "Editor", "Analyst", "Support"],
      },
    ],
  },
  {
    title: i18n.t("navigation.orders"),
    titleEng: "Orders",
    route: "orders",
    icon: "ShoppingCartIcon",
    id: 4,
  },
  {
    title: i18n.t("navigation.customers"),
    titleEng: "Customers",
    route: "customers",
    icon: "UserIcon",
    id: 1,
  },
  // lmao i'm not doing this
  // {
  //   title: i18n.t("navigation.analytics"),
  //   titleEng: "Analytics",
  //   route: "analytics",
  //   icon: "PieChartIcon",
  //   id: 5,
  // },
  {
    title: i18n.t("navigation.notifications"),
    titleEng: "Notifications",
    route: "notifications",
    icon: "BellIcon",
    id: 8,
  },
  {
    title: i18n.t("navigation.parameters"),
    titleEng: "Parameters",
    route: "parameters",
    icon: "SettingsIcon",
    id: 9,
    children: [
      {
        title: i18n.t("navigation.regions"),
        route: "regions",
        allowedRoles: ["Admin", "Editor", "Analyst"],
      },
      {
        title: i18n.t("navigation.categories"),
        route: "categories",
        allowedRoles: ["Category Manager", "Admin", "Editor", "Analyst"],
      },
      {
        title: i18n.t("navigation.attributes"),
        route: "attributes",
        allowedRoles: ["Admin", "Editor", "Analyst"],
      },
    ],
  },
  {
    titleEng: "Promotions",
    title: i18n.t("navigation.promotions"),
    icon: "PercentIcon",
    id: 10,
    children: [
      {
        title: i18n.t("navigation.campaigns"),
        route: "campaigns",
        allowedRoles: ["Admin", "Editor", "Analyst", "Support", "Accountant"],
      },
      {
        title: i18n.t("navigation.promo-codes"),
        route: "codes",
        allowedRoles: ["Admin", "Editor", "Analyst", "Support", "Accountant"],
      },
    ],
  },
];
